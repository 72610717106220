<template>
  <div>
    <v-dialog
      transition="dialog-bottom-transition"
      max-width="600px"
      v-model="d_forget"
      @click:outside="$emit('close')"
      @keydown.esc="$emit('close')"
    >
      <template>
        <v-card rounded="xl">
          <div class="d-flex justify-end pa-2">
            <v-btn icon dark @click="$emit('close')">
              <v-icon color="#052633">mdi-close</v-icon>
            </v-btn>
          </div>
          <v-card-text v-if="!isSuccessForgetPassword">
            <h1 class="text-left mb-2" style="color: black">Lupa Password</h1>
            <h3
              class="text-left mb-8"
              style="font-weight: normal; color: #445750"
            >
              Silakan masukkan email yang sudah terdaftar
            </h3>
            <h3 class="text-left mb-2" style="color: #052633">Email</h3>
            <v-text-field
              outlined
              v-model="email_forgetPassword"
              required
              color="#377F65"
              :rules="emailRules"
              placeholder="Tulis email Anda disini"
            ></v-text-field>
            <v-btn
              block
              class="mb-3 pa-6 text-capitalize"
              color="#052633"
              v-if="!loading_forgot"
              depressed
              dark
              @click="requestLinkPassword"
            >
              Kirim
            </v-btn>
            <v-btn
              block
              class="mb-3 pa-6 text-capitalize"
              depressed
              v-if="loading_forgot"
              color="#052633"
              dark
            >
              Loading..
            </v-btn>
          </v-card-text>
          <v-card-text v-if="isSuccessForgetPassword">
            <div class="d-flex align-center justify-center mb-2">
              <div style="width: 60%">
                <img
                  src="../../assets/img/success Kirim Email.png"
                  width="100%"
                />
              </div>
            </div>
            <h1 class="text-center mb-2" style="color: black">Success!</h1>
            <h3
              class="text-center mb-8"
              style="font-weight: normal; color: #445750"
            >
              Kami sudah kirim cara untuk mengganti password ke email Anda.
              Mohon cek email anda.
            </h3>
            <v-btn
              block
              class="mb-3 pa-6 text-capitalize"
              color="#006341"
              depressed
              dark
              @click="$emit('close')"
            >
              Ok
            </v-btn>
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["d_forget"],
  data() {
    return {
      email_forgetPassword: "",
      loading_forgot: false,
      isSuccessForgetPassword: false,
      emailRules: [
        (v) => !!v || "Email is required",
        (v) =>
          /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "Email must be valid",
      ],
    };
  },
  methods: {
    requestLinkPassword() {
      this.loading_forgot = "true";
      let body = {
        email: this.email_forgetPassword,
      };
      let data = {
        path: `auth/password/reset-request`,
        body: body,
      };
      this.$store
        .dispatch("auth/ReqResetPassword", data)
        .then((data) => {
          console.log(data);
          if (data.status) {
            this.isSuccessForgetPassword = true;
            this.loading_forgot = false;
          } else {
            this.$store.dispatch("notify", {
              msg: data.email,
              type: "error",
            });
            this.loading_forgot = false;
          }
        })
        .catch((e) => {
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
          this.loading_forgot = false;
        });
    },
  },
};
</script>

<style></style>
