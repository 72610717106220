<template>
  <div>
    <v-row no-gutters class="bg_login full_w full_h">
      <v-col cols="12" md="5">
        <div class="side_banner flex-column">
          <v-img src="../../assets/icon/Logo.png" contain class="logo"> </v-img>
          <div class="width_parent_img d-flex align-center justify-center">
            <v-img src="../../assets/img/photo Login.png" class="img_login">
            </v-img>
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="7">
        <div class="side_form flex-column">
          <div class="parent_logo_icon2">
            <v-img src="../../assets/icon/icon Login.png" class="logo_icon2">
            </v-img>
          </div>
          <div class="parent_logo_icon2 flex-column">
            <h1 class="txt_welcome">Selamat Datang di Biro Psikologi</h1>
            <h4 class="small not_bold text-center" style="color: #70a5a4">
              Silakan masuk untuk melanjutkan
            </h4>
          </div>
          <form @submit.prevent="login" class="parent_txt">
            <h4 class="txt_label">Email</h4>
            <v-text-field
              v-model="name"
              hide-details
              solo
              dense
              flat
              background-color="rgba(176, 186, 195, 0.4)"
              class="txt_input rounded-lg"
              placeholder="Masukkan email anda disini"
            ></v-text-field>
            <h4 class="txt_label">Kata Sandi</h4>
            <v-text-field
              v-model="password"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              hide-details
              dense
              background-color="rgba(176, 186, 195, 0.4)"
              flat
              solo
              class="txt_input rounded-lg"
              placeholder="Masukkan kata sandi anda disini"
              @click:append="show = !show"
            ></v-text-field>
            <div class="d-flex align-center justify-end">
              <a @click="forgetPassword" style="color: #052633"
                >Lupa password?</a
              >
            </div>
            <div class="parent_btn_masuk flex-column">
              <v-btn
                class="btn_masuk bg_color1"
                type="login"
                v-if="!loading"
                dark
                x-large
              >
                <h4 class="txt_btn_masuk">Masuk</h4>
              </v-btn>
              <v-btn dark class="btn_masuk bg_color1" x-large v-if="loading" style="pointer-events: none;">
                <h4 class="txt_btn_masuk">Loading...</h4>
              </v-btn>
              <h5
                class="not_bold small text-center not_have"
                style="color: #7c838a"
              >
                Belum mempunyai akun?
                <span style="color: #052633">Hubungi kami</span>
              </h5>
              <div class="d-flex align-center justify-center">
                <v-btn
                  class="btn_sosmed bg_color1"
                  dark
                  @click="
                    sendEmail(
                      'https://mail.google.com/mail/u/0/?fs=1&to=ptharaanargya@gmail.com&su=Biro Psikologi&body=Saya belum punya akun&tf=cm'
                    )
                  "
                  target="blank"
                >
                  <v-icon>mdi-email-outline</v-icon>
                </v-btn>
                <v-btn
                  class="ml-3 bg_color1"
                  dark
                  @click="sendEmail('https://wa.me/+628223377637')"
                  target="blank"
                >
                  <v-icon>mdi-whatsapp</v-icon>
                </v-btn>
              </div>
            </div>
          </form>
          <div class="copyright">
            <h5 class="not_bold txt_copyrigth">
              © 2022 Biro Psikologi. All rights reserved.
            </h5>
          </div>
        </div>
      </v-col>
      <div>
        <forgot-password
          :d_forget="forgotPassword"
          @close="forgotPassword = false"
        />
      </div>
    </v-row>
  </div>
</template>

<script>
import forgotPassword from "../../components/forgotPassword/forgotPassword.vue";
export default {
  components: { forgotPassword },
  name: "login",
  data() {
    return {
      name: "",
      password: "",
      show: false,
      loading: false,
      forgotPassword: false,
    };
  },
  methods: {
    sendEmail(link) {
      window.open(link, "blank");
    },
    login() {
      this.loading = true;
      let data = {
        email: this.name.replace(/ /g, ""),
        password: this.password.replace(/ /g, ""),
      };
      this.$store
        .dispatch("auth/login", data)
        .then((data) => {
          if (data.code == 200) {
            this.$router.go("/");
          } else {
            this.$store.dispatch("notify", {
              msg: data.message,
              type: "error",
            });
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.$store.dispatch("notify", {
            msg: err.message,
            type: "error",
          });
        });
    },
    forgetPassword() {
      this.forgotPassword = true;
    },
  },
};
</script>

<style scoped>
.v-btn:not(.v-btn--round).v-size--x-large {
  height: 65px;
  min-width: 92px;
  padding: 0 23.1111111111px;
}
.v-btn:not(.v-btn--round).v-size--default {
  padding: 0;
  width: 50px;
  height: 50px;
}
@media (min-width: 396px) {
  .v-btn:not(.v-btn--round).v-size--x-large {
    height: 45px;
    min-width: 92px;
    padding: 0px;
  }
  .v-btn:not(.v-btn--round).v-size--default {
    padding: 0px;
    width: 35px;
    height: 35px;
  }
  .parent_txt {
    width: 90%;
  }
  .copyright {
    margin-bottom: -15px;
    margin-top: 35px;
  }
  .txt_copyrigth {
    font-size: 12px;
    color: #7c838a;
    text-align: center;
  }
  .not_have {
    margin: 10px 0px 10px 0px;
  }
  .parent_logo_icon2 {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .parent_btn_masuk {
    margin-top: 10px;
    display: flex;
    align-items: center;
  }
  .btn_masuk {
    width: 100%;
  }
  .logo_icon2 {
    width: 100px;
  }
  .txt_welcome {
    font-size: 18px;
    text-align: center;
    margin: 5px 0 5px 0;
  }
  .small {
    font-size: 12px;
  }
  .txt_btn_masuk {
    font-size: 15px;
  }
  .txt_label {
    margin-top: 15px;
    font-size: 12px;
    font-weight: 500;
    color: #7c838a;
  }
  .width_parent_img {
    width: 50%;
    margin-top: 10px;
  }
  .img_login {
    width: 10%;
    margin-top: 30px;
    object-fit: contain;
  }
  .logo {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 100px;
  }
  .side_banner {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .side_form {
    padding: 25px 10px 10px 0px;
    background: white;
    width: 100%;
    height: 100%;
    border-radius: 25px 25px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}
@media (max-width: 575px) {
  .v-btn:not(.v-btn--round).v-size--x-large {
    height: 46px;
    min-width: 92px;
    padding: 0px;
  }
  .v-btn:not(.v-btn--round).v-size--default {
    padding: 0px;
    width: 40px;
    height: 40px;
  }
  .parent_txt {
    width: 90%;
  }
  .copyright {
    margin-bottom: -20px;
    margin-top: 30px;
  }
  .txt_copyrigth {
    font-size: 12px;
    color: #7c838a;
    text-align: center;
  }
  .not_have {
    margin: 10px 0px 10px 0px;
  }
  .parent_logo_icon2 {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .parent_btn_masuk {
    margin-top: 10px;
    display: flex;
    align-items: center;
  }
  .btn_masuk {
    width: 100%;
  }
  .logo_icon2 {
    width: 100px;
  }
  .txt_welcome {
    font-size: 18px;
    text-align: center;
    margin: 5px 0 5px 0;
  }
  .small {
    font-size: 12px;
  }
  .txt_btn_masuk {
    font-size: 15px;
  }
  .txt_label {
    font-size: 12px;
    font-weight: 500;
    color: #7c838a;
  }
  .width_parent_img {
    width: 40%;
  }
  .img_login {
    width: 10%;
    margin-top: 30px;
    object-fit: contain;
  }
  .logo {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 100px;
  }
  .side_banner {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .side_form {
    padding: 10px;
    background: white;
    width: 100%;
    height: 100%;
    border-radius: 25px 25px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}
/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .v-btn:not(.v-btn--round).v-size--x-large {
    height: 46px;
    min-width: 92px;
    padding: 0px;
  }
  .v-btn:not(.v-btn--round).v-size--default {
    padding: 0px;
    width: 40px;
    height: 40px;
  }
  .parent_txt {
    width: 90%;
  }
  .copyright {
    margin-bottom: -20px;
    margin-top: 30px;
  }
  .txt_copyrigth {
    font-size: 12px;
    color: #7c838a;
    text-align: center;
  }
  .not_have {
    margin: 10px 0px 10px 0px;
  }
  .parent_logo_icon2 {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .parent_btn_masuk {
    margin-top: 10px;
    display: flex;
    align-items: center;
  }
  .btn_masuk {
    width: 100%;
  }
  .logo_icon2 {
    width: 100px;
  }
  .txt_welcome {
    font-size: 18px;
    text-align: center;
    margin: 5px 0 5px 0;
  }
  .small {
    font-size: 12px;
  }
  .txt_btn_masuk {
    font-size: 15px;
  }
  .txt_label {
    font-size: 12px;
    font-weight: 500;
    color: #7c838a;
  }
  .width_parent_img {
    width: 40%;
  }
  .img_login {
    width: 10%;
    margin-top: 30px;
    object-fit: contain;
  }
  .logo {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 100px;
  }
  .side_banner {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .side_form {
    padding: 10px;
    background: white;
    width: 100%;
    height: 100%;
    border-radius: 25px 25px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}
/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .v-btn:not(.v-btn--round).v-size--x-large {
    height: 45px;
    min-width: 92px;
    padding: 0px;
  }
  .v-btn:not(.v-btn--round).v-size--default {
    padding: 0px;
    width: 40px;
    height: 40px;
  }
  .parent_txt {
    width: 90%;
  }
  .copyright {
    margin-bottom: -20px;
    margin-top: 30px;
  }
  .txt_copyrigth {
    font-size: 12px;
    color: #7c838a;
    text-align: center;
  }
  .not_have {
    margin: 10px 0px 10px 0px;
  }
  .parent_logo_icon2 {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .parent_btn_masuk {
    margin-top: 10px;
    display: flex;
    align-items: center;
  }
  .btn_masuk {
    width: 100%;
  }
  .logo_icon2 {
    width: 100px;
  }
  .txt_welcome {
    font-size: 18px;
    text-align: center;
    margin: 5px 0 5px 0;
  }
  .small {
    font-size: 12px;
  }
  .txt_btn_masuk {
    font-size: 15px;
  }
  .txt_label {
    font-size: 12px;
    font-weight: 500;
    color: #7c838a;
  }
  .width_parent_img {
    width: 30%;
  }
  .img_login {
    width: 10%;
    margin-top: 30px;
    object-fit: contain;
  }
  .logo {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 100px;
  }
  .side_banner {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
  .side_form {
    background: white;
    border-radius: 25px 25px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}

@media (min-width: 960px) {
  .v-btn:not(.v-btn--round).v-size--x-large {
    height: 45px;
    min-width: 92px;
    padding: 0px;
  }
  .v-btn:not(.v-btn--round).v-size--default {
    padding: 0px;
    width: 40px;
    height: 40px;
  }
  .width_parent_img {
    width: 70%;
  }
  .side_form {
    width: 100%;
    height: 100%;
    background: white;
    border-radius: 25px 0px 0px 25px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .side_banner {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
  .txt_welcome {
    font-size: 26px;
    margin: 10px 0 10px 0;
    text-align: center;
  }
  .small {
    font-size: 16px;
  }
  .parent_btn_masuk {
    margin-top: 20px;
    display: flex;
    align-items: center;
  }
  .btn_masuk {
    width: 238px;
    margin-bottom: 10px;
    text-transform: capitalize;
  }
  .txt_btn_masuk {
    font-size: 18px;
  }
  .txt_label {
    font-size: 18px;
    font-weight: 500;
    color: #7c838a;
  }
  .logo {
    position: absolute;
    top: 30px;
    left: 30px;
    width: 154px;
  }
  .img_login {
    width: 100%;
    object-fit: contain;
  }
  .parent_logo_icon2 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }
  .logo_icon2 {
    width: 150px;
  }
  .txt_input {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .parent_txt {
    width: 75%;
  }
  .not_have {
    margin: 15px 0px 15px 0px;
  }
  .copyright {
    margin-bottom: -70px;
    margin-top: 70px;
  }
  .txt_copyrigth {
    font-size: 15px;
    color: #7c838a;
    text-align: center;
  }
}
/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .v-btn:not(.v-btn--round).v-size--x-large {
    height: 35px;
    min-width: 92px;
    padding: 0px;
  }
  .v-btn:not(.v-btn--round).v-size--default {
    padding: 0px;
    width: 42px;
    height: 35px;
  }
  .width_parent_img {
    width: 70%;
  }
  .side_form {
    width: 100%;
    height: 100%;
    background: white;
    border-radius: 25px 0px 0px 25px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .side_banner {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
  .txt_welcome {
    font-size: 24px;
    margin: 10px;
    text-align: center;
  }
  .small {
    font-size: 15px;
    margin: 10px;
  }
  .parent_btn_masuk {
    margin-top: 15px;
    display: flex;
    align-items: center;
  }
  .btn_masuk {
    width: 238px;
    margin-bottom: 20px;
    text-transform: capitalize;
  }
  .txt_btn_masuk {
    font-size: 18px;
  }
  .txt_label {
    font-size: 18px;
    font-weight: 500;
    color: #7c838a;
  }
  .logo {
    position: absolute;
    top: 30px;
    left: 30px;
    width: 154px;
  }
  .img_login {
    width: 100%;
    object-fit: contain;
  }
  .parent_logo_icon2 {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .logo_icon2 {
    width: 100px;
  }
  .txt_input {
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .parent_txt {
    width: 70%;
  }
  .not_have {
    margin: 5px 0px 5px 0px;
  }
  .copyright {
    margin-bottom: -10px;
    margin-top: 20px;
  }
  .txt_copyrigth {
    font-size: 13px;
    color: #7c838a;
    text-align: center;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .v-btn:not(.v-btn--round).v-size--x-large {
    height: 35px;
    min-width: 92px;
    padding: 0px;
  }
  .v-btn:not(.v-btn--round).v-size--default {
    padding: 0px;
    width: 42px;
    height: 35px;
  }
  .width_parent_img {
    width: 70%;
  }
  .side_form {
    width: 100%;
    height: 100%;
    background: white;
    border-radius: 25px 0px 0px 25px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .side_banner {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
  .txt_welcome {
    font-size: 24px;
    margin: 10px;
    text-align: center;
  }
  .small {
    font-size: 15px;
    margin: 10px;
  }
  .parent_btn_masuk {
    margin-top: 15px;
    display: flex;
    align-items: center;
  }
  .btn_masuk {
    width: 238px;
    margin-bottom: 20px;
    text-transform: capitalize;
  }
  .txt_btn_masuk {
    font-size: 18px;
  }
  .txt_label {
    font-size: 18px;
    font-weight: 500;
    color: #7c838a;
  }
  .logo {
    position: absolute;
    top: 30px;
    left: 30px;
    width: 154px;
  }
  .img_login {
    width: 100%;
    object-fit: contain;
  }
  .parent_logo_icon2 {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .logo_icon2 {
    width: 100px;
  }
  .txt_input {
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .parent_txt {
    width: 70%;
  }
  .not_have {
    margin: 5px 0px 5px 0px;
  }
  .copyright {
    margin-bottom: -10px;
    margin-top: 20px;
  }
  .txt_copyrigth {
    font-size: 13px;
    color: #7c838a;
    text-align: center;
  }
}
@media (min-width: 1440px) {
  .width_parent_img {
    width: 70%;
  }
  .side_form {
    width: 100%;
    height: 100%;
    background: white;
    border-radius: 25px 0px 0px 25px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .side_banner {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
  .txt_welcome {
    font-size: 36px;
    margin: 15px 0 15px 0;
    text-align: center;
  }
  .small {
    font-size: 18px;
  }
  .parent_btn_masuk {
    margin-top: 30px;
    display: flex;
    align-items: center;
  }
  .btn_masuk {
    width: 238px;
    margin-bottom: 10px;
    text-transform: capitalize;
  }
  .txt_btn_masuk {
    font-size: 18px;
  }
  .txt_label {
    font-size: 20px;
    font-weight: 500;
    color: #7c838a;
  }
  .logo {
    position: absolute;
    top: 30px;
    left: 30px;
    width: 154px;
  }
  .img_login {
    width: 100%;
    object-fit: contain;
  }
  .parent_logo_icon2 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }
  .logo_icon2 {
    width: 40%;
    /* width: 200px; */
  }
  .txt_input {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .parent_txt {
    width: 75%;
  }
  .not_have {
    margin: 20px 0px 20px 0px;
  }
  .copyright {
    margin-bottom: -30px;
    margin-top: 30px;
  }
  .txt_copyrigth {
    font-size: 18px;
    color: #7c838a;
    text-align: center;
  }
}
</style>
